/**
 * External dependencies.
 */

import React from "react";

import { Provider } from "react-redux";
import createStore from "../store";
import { GatsbyBrowser } from "gatsby";
import { AppProvider } from "./AppProvider";
// import { clientWoo } from "../apollo/client-woo";

/**
 * Internal dependencies.
 */

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */

export const WrapWithProvider: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  console.log("wrapRootElement");
  const store = createStore();

  return (
    <Provider store={store}>
      <AppProvider>{element}</AppProvider>
    </Provider>
  );
};
